import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { productVariant } from '*/ProductVariant.graphql'
import gql from 'graphql-tag'

import {
  EnterpriseContext,
  getSafeUrlParametersFromWindow,
  useEnterpriseContext,
  useLoginCheck,
  useSiteConfig,
  useSiteDefinition,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { useWishlistNotificationContext } from '@thg-commerce/enterprise-core/src/WishlistNotificationProvider'
import {
  getColumboEventData,
  useBackendEventNormaliser,
} from '@thg-commerce/enterprise-metrics'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  DataLayerGA4,
  productEventsCategory,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { ProductVariant } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import {
  WishlistButtonPDPStyle,
  WishlistButtonStyleInterface,
} from '@thg-commerce/enterprise-pages/src/Product/theme'
import { Tooltip } from '@thg-commerce/gravity-elements'
import { Appearance } from '@thg-commerce/gravity-elements/Tooltip/Tooltip'

import { removeCurrencySymbol } from '../../../Price/Price'
import { ContentItem } from '../ProductBlock'

import { StyledButton, WishlistContentContainer, WishlistIcon } from './styles'
import { WishlistTooltipContent } from './WishlistTooltipContent'

export enum WishlistButtonType {
  SMALL = 'SMALL_BUTTON',
  HEART = 'HEART_BUTTON',
  TEXT = 'TEXT_BUTTON',
}
export interface WishlistButtonProps {
  sku: number
  title?: string
  price?: string
  inWishlist?: boolean
  heartButton?: boolean
  className?: string
  buttonWithoutText?: boolean
  i18nText: {
    addToWishlistText: string
    savedToWishlistText: string
    wishlistTooltip: {
      closeButtonText: string
    }
    wishlistTooltipContent: {
      loginSignupText: string
      toUseWishlistText: string
    }
  }
  dataLayerBrand?: string
  addToWishlistCallback?: (sku: number) => void
  removeFromWishlistCallback?: (sku: number) => void
  buttonStyle?: WishlistButtonStyleInterface
  buttonType: WishlistButtonType
  onWishlistBeacon?: Beacon
  open?: boolean
  wishlistPDPButtonStyle?: WishlistButtonPDPStyle
  externalIdentifier?: string | null
  brandName?: string
  itemCategories?: ContentItem[]
  variantChildSku?: string
  variantIdentifier?: string
  index?: number
  productVariant?: ProductVariant
}

export const ADD_PRODUCT_TO_WISHLIST_MUTATION = gql`
  mutation AddProductToWishlist($sku: SKU!) {
    addProductToWishlist(sku: $sku) @client
  }
`

export const REMOVE_PRODUCT_FROM_WISHLIST_MUTATION = gql`
  mutation RemoveProductFromWishlist($sku: SKU!) {
    removeProductFromWishlist(sku: $sku) @client
  }
`

export const WishlistButton = (props: WishlistButtonProps) => {
  const { currency } = useEnterpriseContext()
  const {
    useGA4EnhancedEcom,
    enableSponsoredAds,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    alternateProductKeysForCategories,
  } = useSiteConfig()
  const isLoggedIn = useLoginCheck()

  const [inWishlist, setInWishlist] = React.useState(props.inWishlist)
  const { showWishlistNotification } = useWishlistNotificationContext()
  const transmit = useTransmit()
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const {
    value: [getExtensions],
  } = EnterpriseCtx.extensionsRef
  const { defaultLocale, subsite, siteId, channel } = useSiteDefinition()
  const propertyArgs = {
    defaultLocale,
    subsite,
    siteId,
    channel,
    customerLocation: EnterpriseCtx.currentLocation,
  }

  const normaliseBackendEvent = useBackendEventNormaliser()
  const extensions = getExtensions()

  const [addToWishlist] = useMutation<
    { addProductToWishlist: boolean },
    { sku: number }
  >(ADD_PRODUCT_TO_WISHLIST_MUTATION, {
    variables: {
      sku: props.sku,
    },
    onCompleted: (data) => {
      if (data?.addProductToWishlist) {
        showWishlistNotification?.current?.()
        setInWishlist(true)
        transmit({
          type: 'wishlist_interaction_event',
          payload: normaliseBackendEvent({
            eventData: {
              type: 'wishlist_interaction_event',
              items: [
                {
                  product_group: {
                    id: props.sku,
                    selected_variant: {
                      sku: props.sku,
                    },
                  },
                },
              ],
            },
            requestData: {
              ...(extensions?.LoggerLinkData || {
                start_timestamp: Date.now(),
                duration_ms: 0,
              }),
              url: window.location.href,
            },
          }),
        })

        addToWishlistG4Event()

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'added to wishlist',
            eventCategory: 'Wishlist Engagement',
            eventLabel: `${props.sku}`,
          },
        })

        if (enableSponsoredAds && props.onWishlistBeacon) {
          navigator.sendBeacon(props.onWishlistBeacon.url)
        }

        props.addToWishlistCallback && props.addToWishlistCallback(props.sku)
      }
    },
  })

  React.useEffect(() => {
    setInWishlist(props.inWishlist)
  }, [props.inWishlist])

  const getItemId = (externalIdentifier, sku) => {
    if (
      (useExternalIdentifier || useExternalIdentifierInSchema) &&
      externalIdentifier
    ) {
      return externalIdentifier
    }
    return sku
  }

  const getItemVariant = (
    productVariant,
    variantIdentifier,
    variantChildSku,
    externalIdentifier,
  ) => {
    if (externalIdentifier) {
      return externalIdentifier
    }
    if (productVariant?.externalIdentifier) {
      return productVariant.externalIdentifier
    }
    if (useExternalIdentifier || useExternalIdentifierInSchema) {
      return variantIdentifier
    }
    return variantChildSku || ''
  }

  const addToWishlistG4Event = () => {
    if (useGA4EnhancedEcom) {
      pushToEventGA4<DataLayerGA4>({
        event: 'ecom_event',
        event_name: 'add_to_wishlist',
        ecommerce: {
          currencyCode: currency,
          items: [
            {
              item_name: props?.title,
              item_id: getItemId(props?.externalIdentifier, props?.sku),
              price: removeCurrencySymbol(props?.price),
              item_brand: props?.brandName || props.dataLayerBrand,
              index: props.index || 0,
              item_variant: getItemVariant(
                props?.productVariant,
                props?.variantIdentifier,
                props?.variantChildSku,
                props?.externalIdentifier,
              ),
              ...productEventsCategory(
                props.itemCategories,
                alternateProductKeysForCategories,
              ),
            },
          ],
        },
      })
    }
  }

  const [removeFromWishlist] = useMutation<
    { removeProductFromWishlist: boolean },
    { sku: number }
  >(REMOVE_PRODUCT_FROM_WISHLIST_MUTATION, {
    variables: {
      sku: props.sku,
    },
    fetchPolicy: 'no-cache', // Void return type on mutation breaks cache-writing
    onCompleted: (data) => {
      data?.removeProductFromWishlist && setInWishlist(false)

      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'removed from wishlist',
          eventCategory: 'Wishlist Engagement',
          eventLabel: `${props.sku}`,
        },
      })
      pushToEventGA4({
        event: 'elysiumEvent',
        eventData: {
          eventAction: 'removed from wishlist',
          eventCategory: 'Wishlist Engagement',
          eventLabel: props.sku,
          eventPage: typeof window !== undefined && window.location.pathname,
        },
      })

      props.removeFromWishlistCallback &&
        props.removeFromWishlistCallback(props.sku)
    },
  })
  const sendWishlistButtonClickedEvent = () => {
    transmit({
      type: 'columbo',
      payload: getColumboEventData({
        propertyArgs,
        argumentsObj: {
          '0': 'Accessibility',
          '1': 'Clicked',
          '2': 'WishlistButton',
        },
        requestArgs: {
          client_timestamp: Math.round(Date.now() / 1000),
          url: window.location.href,
        },
        eventData: {
          type: 'click',
          subtype: 'productAddToWishlist_button_default',
          contents: [],
        },
        nonce: EnterpriseCtx.metricNonce,
      }),
    })
  }

  React.useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const { addSKUToWishlist } = getSafeUrlParametersFromWindow<{
      addSKUToWishlist: string
    }>(window, ['addSKUToWishlist'])

    addSKUToWishlist &&
      parseInt(addSKUToWishlist, 10) === props.sku &&
      addToWishlist({ variables: { sku: props.sku } })
  }, [isLoggedIn, addToWishlist, props.sku])

  const onLoggedOutTooltipChange = (open: boolean) => {
    if (open && !isLoggedIn) {
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'clicked add to wishlist | logged out',
          eventCategory: 'Wishlist Engagement',
          eventLabel: `${props.sku}`,
        },
      })
      sendWishlistButtonClickedEvent()
    }
  }

  const onCloseLoggedOutTooltip = () => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'close login pop-up',
        eventCategory: 'Wishlist Engagement',
        eventLabel: `${props.sku}`,
      },
    })
  }
  if (!isLoggedIn) {
    return (
      <Tooltip
        className={props.className}
        appearance={Appearance.light}
        triggerContent={
          <StyledButton
            data-testid="add-to-wishlist-button"
            buttonType={props.buttonType}
            buttonStyle={props.buttonStyle}
            emphasis="low"
            sizing="regular"
            aria-expanded={props.open}
            ariaLabel={props.i18nText.addToWishlistText}
            wishlistPDPButtonStyle={props.wishlistPDPButtonStyle}
          >
            {props.buttonType === WishlistButtonType.HEART ? (
              <WishlistIcon heartButton={true} />
            ) : props.buttonType === WishlistButtonType.SMALL ? (
              <WishlistIcon margin={1} />
            ) : (
              <WishlistContentContainer>
                <WishlistIcon />
                {props.i18nText.addToWishlistText}
              </WishlistContentContainer>
            )}
          </StyledButton>
        }
        onChange={onLoggedOutTooltipChange}
        onClose={onCloseLoggedOutTooltip}
        content={
          <WishlistContentContainer>
            <WishlistTooltipContent
              sku={props.sku}
              i18nText={props.i18nText.wishlistTooltipContent}
            />
          </WishlistContentContainer>
        }
        i18nCloseButtonText={props.i18nText.wishlistTooltip.closeButtonText}
      />
    )
  }

  return (
    <StyledButton
      className={props.className}
      buttonType={props.buttonType}
      buttonStyle={props.buttonStyle}
      data-testid={
        inWishlist ? 'remove-from-wishlist-button' : 'add-to-wishlist-button'
      }
      emphasis="low"
      sizing="regular"
      onClick={() => {
        sendWishlistButtonClickedEvent()
        inWishlist
          ? removeFromWishlist({ variables: { sku: props.sku } })
          : addToWishlist({ variables: { sku: props.sku } })
      }}
      ariaLabel={
        inWishlist
          ? props.i18nText.savedToWishlistText
          : props.i18nText.addToWishlistText
      }
      inWishlist={inWishlist}
      wishlistPDPButtonStyle={props.wishlistPDPButtonStyle}
    >
      {props.buttonType === WishlistButtonType.HEART ? (
        <WishlistIcon heartButton={true} />
      ) : props.buttonType === WishlistButtonType.SMALL ? (
        <WishlistIcon margin={1} />
      ) : (
        <WishlistContentContainer>
          <WishlistIcon />
          {inWishlist
            ? props.i18nText.savedToWishlistText
            : props.i18nText.addToWishlistText}
        </WishlistContentContainer>
      )}
    </StyledButton>
  )
}

export default WishlistButton
